<template>
  <div class="edit-currency">
    <loading v-if="isLoading" />
    <validation-observer v-else ref="editCurrency">
      <overlay-component :is-loading="isBusy" />
      <b-form>
        <b-card :title="$t('currencies.edit.head')">
          <b-row>
            <b-col class="col-6 col-lg-6">
              <b-form-group>
                <validation-provider :name="$t('currencies.edit.currencyName')">
                  <b-form-input v-model="formData.currency_code" disabled />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <validation-provider
                  :name="$t('currencies.edit.countries')"
                  rules="required"
                >
                  <multiselect
                    v-model="formData.countries"
                    :tag-placeholder="
                      $t('currencies.edit.AddCoursePlaceHolder')
                    "
                    :placeholder="$t('currencies.edit.addCountry')"
                    label="name"
                    track-by="name"
                    :options="countries"
                    :multiple="false"
                    :taggable="true"
                    :disabled="true"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('currencies.edit.headTwo')">
          <b-form-group>
            <validation-provider
              :name="$t('currencies.edit.countries')"
              rules="required"
            >
              <b-form-radio
                v-model="isManual"
                name="some-radios"
                :value="false"
                class="mb-1"
                ><div class="radio-label">
                  <span class="head">
                    {{ $t("currencies.edit.auto") }}
                  </span>
                  <span class="description">
                    {{ $t("currencies.edit.autoBody") }}
                  </span>
                </div>
              </b-form-radio>
              <b-form-radio v-model="isManual" name="some-radios" :value="true"
                ><div class="radio-label">
                  <span class="head">
                    {{ $t("currencies.edit.manual") }}
                  </span>
                  <span class="description">
                    {{ $t("currencies.edit.manualBody") }}
                  </span>
                </div></b-form-radio
              >
              <validation-provider
                :name="$t('currencies.edit.value')"
                rules="required"
              >
                <b-form-group v-if="isManual" class="col col-4 mt-2">
                  <b-input-group prepend="£">
                    <b-form-input
                      v-model="formData.exchange_value"
                      :formatter="$helpers.intOnlyFormater"
                    />
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-row>
            <b-col class="col-lg-4 col-md-12">
              <b-form-group :label="$t('currencies.edit.pound')">
                <b-input-group prepend="£">
                  <b-form-input
                    v-model="testPound"
                    :formatter="$helpers.intOnlyFormater"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="d-flex align-items-end">
              <b-form-group>
                <h5>
                  <span> {{ totalAfter }} </span> {{ formData.currency_code }}
                </h5>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="d-flex justify-content-end">
          <b-button variant="primary" :disabled="isBusy" @click="submitForm">
            {{ $t("currencies.edit.saveChanges") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import {
  BForm,
  BCard,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BFormRadio,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import { required, email, min } from "@validations";
import Loading from "@/components/loading/loading.vue";
import overlayComponent from "@/components/shared/OverlayComponent/index.vue";
export default {
  name: "EditCurrency",
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider,
    BCol,
    BForm,
    BCard,
    BRow,
    BFormGroup,
    BFormInput,
    required,
    BFormRadio,
    Loading,
    BInputGroup,
    BButton,
    overlayComponent,
  },
  data() {
    return {
      formData: {
        exchange_value: null,
        currency_code: null,
        countries: [],
      },
      testPound: 20,
      isManual: true,
      countries: [],
      isLoading: false,
      archive: {},
      isBusy: false,
    };
  },
  computed: {
    totalAfter() {
      return this.testPound * this.formData.exchange_value;
    },
  },
  watch: {
    "$route.params.id": {
      handler(newValue) {
        this.getMyData(newValue);
      },
    },
  },
  created() {
    this.getMyData(this.$route.params.id);
    this.getCountries();
  },
  methods: {
    async getMyData(_id) {
      this.isLoading = true;
      try {
        const response = await this.$http.get(`admin/currencies/${_id}`);
        if (response.status === 200 || response.status === 201) {
          for (const item in this.formData) {
            this.formData[item] = response.data.data[item];
            this.archive[item] = response.data.data[item];
            this.isManual = !Boolean(response.data.data.is_auto)
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getCountries() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("admin/countries");
        if (response.status === 200 || response.status === 201) {
          response.data.data.forEach(({ id, name }) => {
            this.countries.push({ id, name });
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm() {
      this.isBusy = true;
      try {
        const formData = this.collectData();

        const request = await this.$http.post(
          `admin/currencies/${this.$route.params.id}?_method=put`,
          formData
        );
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast(
            "success",
            request.data.message,
            request.data.message
          );
          this.$router.push({ name: "Currencies" });
        }
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    collectData() {
      const formData = new FormData();
      if (this.isManual) {
        formData.append("exchange_value", this.formData.exchange_value);
      } else {
        formData.append("is_auto", 1);
      }
      return formData;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./_index.scss";
</style>
